// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advertising-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\advertising.js" /* webpackChunkName: "component---src-pages-advertising-js" */),
  "component---src-pages-business-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-impressum-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifestyle-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\lifestyle.js" /* webpackChunkName: "component---src-pages-lifestyle-js" */),
  "component---src-pages-more-photos-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\more-photos.js" /* webpackChunkName: "component---src-pages-more-photos-js" */),
  "component---src-pages-portraits-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\portraits.js" /* webpackChunkName: "component---src-pages-portraits-js" */),
  "component---src-pages-sports-body-js": () => import("C:\\Users\\Carsten\\Code\\frank-gatsby\\src\\pages\\sports-body.js" /* webpackChunkName: "component---src-pages-sports-body-js" */)
}

